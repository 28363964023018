import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import Button from "../../components/button";
import { closeIcon, gallery, logoWhite } from "../../resources/images";
import { useAppData } from "../../providers/AppDataProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { getTeamMemberDetailsApi } from "../../networking/api/team";
import Loader from "../../components/loader";
import { Helmet } from "react-helmet";

const TeamMemberDetailsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userId = location.pathname.slice(
    location.pathname.lastIndexOf("/") + 1
  );
  const { strings, currentLanguage } = useAppData();
  const [emailCopied, setEmailCopied] = useState(false);
  const [phoneCopied, setPhoneCopied] = useState(false);
  const [itemData, setItemData] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //api for getting team memberdetails individually
  useEffect(() => {
    if (userId) {
      try {
        getTeamMemberDetailsApi(userId).then((res) => {
          if (res.data.type === "success") {
            setItemData(res.data.data);
          } else {
            console.log(res.data.message);
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [userId]);

  const copiedEmail = () => {
    setEmailCopied(true);
    window.navigator.clipboard.writeText(itemData?.email);
    // setTimeout(() => {
    //   setEmailCopied(false);
    // }, 1000);
  };
  const copiedPhoneNumber = () => {
    setPhoneCopied(true);
    window.navigator.clipboard.writeText(itemData?.phone_number);
    // setTimeout(() => {
    //   setPhoneCopied(false);
    // }, 1000);
  };
  const linkedinButtonAction = () => {
    window.open(itemData?.linkedin_url, "__blank");
  };
  const renderCloseBtn = () => {
    return (
      <div className={styles.topLogoContainerStyle}>
        <div
          className={styles.logoImgWrapperStyle}
          onClick={() => {
            navigate("/");
          }}
        >
          <img src={logoWhite} className={styles.imgStyle} />
        </div>

        <div className={styles.closeBtnStyle} onClick={() => navigate(-1)}>
          <p className={styles.closeBtnTextStyle}>{strings.common.close}</p>
          <img src={closeIcon} />
        </div>
      </div>
    );
  };
  const renderBtns = () => {
    return (
      <div className={styles.btnContainerStyle}>
        {!emailCopied ? (
          <Button
            onClick={() => {
              copiedEmail();
              setPhoneCopied(false);
            }}
            buttonName={strings.common.emailCaps}
            btnStyle={styles.btnStyle}
          />
        ) : (
          <div className={styles.btnStyle2}>
            <p>{itemData?.email}</p>
          </div>
        )}
        {!phoneCopied ? (
          <Button
            onClick={() => {
              copiedPhoneNumber();
              setEmailCopied(false);
            }}
            buttonName={strings.common.phoneNUmberCaps}
            btnStyle={styles.btnStyle}
          />
        ) : (
          <div className={styles.btnStyle2}>
            <p>{itemData?.phone_number}</p>
          </div>
        )}
        <Button
          onClick={() => linkedinButtonAction()}
          buttonName={strings.common.linkedin.toUpperCase()}
          btnStyle={styles.btnStyle}
        />
      </div>
    );
  };
  return (
    <>
      {!itemData ? (
        <Loader />
      ) : (
        <div className={styles.modalContainerStyle}>
          <Helmet>
            <meta name="description" content="Useful Legal Services" />
            <meta name="keywords" content="Useful Legal Services" />
            <title>RosholmDell | {itemData?.name}</title>
          </Helmet>
          {renderCloseBtn()}
          <div className={styles.subcaontainerStyle}>
            <div className={styles.imgContainerStyle}>
              <img src={itemData?.image} className={styles.imageStyle} />
            </div>
            <div className={styles.detailsContainerStyle}>
              <div className={styles.titleContainerStyle}>
                <h2 className={styles.modalTitleTextStyle}>{itemData?.name}</h2>
                <p className={styles.modalTitleDesTextStyle}>
                  {itemData?.designation[currentLanguage]}
                </p>
              </div>
              {/* // Competence */}
              <div className={styles.otherDetailsStyle}>
                {itemData?.competence && (
                  <div className={styles.otherDetailsSubStyle}>
                    <p className={styles.subTitleStyle}>
                      {strings.common.competence}
                    </p>
                    <div className={styles.listTextStyle}>
                      {itemData?.competence &&
                        itemData?.competence.map((item, index) => {
                          return (
                            <p
                              key={index}
                              className={
                                !item[currentLanguage]
                                  ? styles.subTitleDescTextStyle
                                  : styles.subTitleDescStyle
                              }
                            >
                              {item[currentLanguage]}
                            </p>
                          );
                        })}
                    </div>
                  </div>
                )}
                {/* //Experience */}
                {itemData?.experience && (
                  <div className={styles.otherDetailsSubStyle}>
                    <p className={styles.subTitleStyle}>
                      {strings.common.experiance}
                    </p>
                    <div className={styles.listTextStyle}>
                      {itemData?.experience &&
                        itemData?.experience.map((item, index) => {
                          return (
                            <div>
                              <p
                                className={
                                  !item[currentLanguage]
                                    ? styles.subTitleDescTextStyle
                                    : styles.subTitleDescStyle2
                                }
                              >
                                {item[currentLanguage]}
                              </p>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}
                {/* // Education */}
                {itemData?.education && (
                  <div className={styles.otherDetailsSubStyle}>
                    <p className={styles.subTitleStyle}>
                      {strings.common.education}
                    </p>
                    <div className={styles.listTextStyle}>
                      {itemData?.education &&
                        itemData?.education.map((item, index) => {
                          return (
                            <div>
                              <p
                                className={
                                  !item[currentLanguage]
                                    ? styles.subTitleDescTextStyle
                                    : styles.subTitleDescStyle2
                                }
                              >
                                {item[currentLanguage]}
                              </p>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}
              </div>
              {renderBtns()}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TeamMemberDetailsPage;
