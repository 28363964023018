import React, { useEffect } from "react";
import styles from "./styles.module.css";
import commonStyles from "../../components/css/common.module.css";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import { useAppData } from "../../providers/AppDataProvider";
import { orangeRightUpArrow } from "../../resources/images";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
const ServicesAndSolutionsPage = () => {
  const { strings, modalOpen, focus, setFocus } = useAppData();
  useEffect(() => {
    setFocus(true);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [modalOpen]);

  const Services = [
    {
      title: strings.servicePage.commercialIpTechnology,
      url: "/services&solutions/commercial&iptechnology",
    },

    {
      title: strings.servicePage.disputeResolution,
      url: "/services&solutions/disputeresolution",
    },
    {
      title: strings.servicePage.complainceAndRegulatory,
      url: "/services&solutions/compliance&regulatory",
    },
    {
      title: strings.servicePage.corporateAndPrivate,
      url: "/services&solutions/corporate&privateequity",
    },
    {
      title: strings.servicePage.startUp,
      url: "/services&solutions/startups",
    },
    {
      title: strings.servicePage.environment,
      url: "/services&solutions/environmental",
    },
    {
      title: strings.servicePage.employment,
      url: "/services&solutions/employment",
    },
    {
      title: strings.servicePage.mergerAndAcquition,
      url: "/services&solutions/mergers&acquisitions",
    },
    {
      title: strings.servicePage.publicAndProcurement,
      url: "/services&solutions/publicprocurement",
    },
    {
      title: strings.servicePage.railwayAndPublic,
      url: "/services&solutions/railway&publictransport",
    },
    {
      title: strings.servicePage.realEstateAndConstruction,
      url: "/services&solutions/realestate&construction",
    },
  ];
  const Solutions = [
    {
      title: strings.servicePage.whistleblowingSolution,
      url: "/services&solutions/whistleblowingsolution",
    },
    {
      title: strings.servicePage.employmentLawSolution,
      url: "/services&solutions/employmentlawasaservice",
    },
    {
      title: strings.servicePage.dpoAsService,
      url: "/services&solutions/dpoasaservice",
    },
    {
      title: strings.servicePage.ipportfoliomanagment,
      url: "/services&solutions/ipportfoliomanagement",
    },
  ];
  const renderTopContent = () => {
    return (
      <div className={styles.headerMainContainerStyle}>
        <h2 className={styles.headerTextStyle}>
          {strings.servicePage.headerText}&nbsp;
          <span className={styles.headerorangeTextStyle}>
            {strings.servicePage.headerOrangeText}
          </span>
          &nbsp;{strings.servicePage.headerText2}
        </h2>
        <p className={styles.headerSubTextStyle}>
          {strings.servicePage.headerContentText}
        </p>
      </div>
    );
  };

  const renderDevider = () => {
    return <div className={styles.deviderStyle}></div>;
  };

  const renderOurServices = () => {
    return (
      <div className={styles.middleContainerStyle}>
        {renderDevider()}
        <div className={styles.ourServicesContainer}>
          <p className={styles.ourServicesTitleTextStyle}>
            {strings.servicePage.ourservices}
          </p>
          <div className={styles.ourServiceSubContainer}>
            {Services.map((service, index) => (
              <Link
                key={index}
                className={styles.eachServiceStyle}
                to={service.url}
                // target={"_blank"}
              >
                <p className={styles.eachServiceTextStyle}>{service.title}</p>
                <div className={styles.arrowImgConatinerStyle}>
                  <img
                    src={orangeRightUpArrow}
                    className={styles.arrowImgStyle}
                  />
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const renderOurSolutions = () => {
    return (
      <div className={styles.middleContainerStyle}>
        {renderDevider()}
        <div className={styles.ourServicesContainer}>
          <p className={styles.ourServicesTitleTextStyle}>
            {strings.servicePage.oursolutions}
          </p>
          <div className={styles.ourServiceSubContainer}>
            {Solutions.map((solution, index) => (
              <Link
                key={index}
                className={styles.eachServiceStyle}
                to={solution.url}
                // target={"_blank"}
              >
                <p className={styles.eachServiceTextStyle}>{solution.title}</p>
                <div className={styles.arrowImgConatinerStyle}>
                  <img
                    src={orangeRightUpArrow}
                    className={styles.arrowImgStyle}
                  />
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    );
  };
  return (
    <div
      className={
        focus
          ? styles.sAndsPageFocusContainerStyle
          : styles.sAndsPageBlurContainerStyle
      }
    >
      <Helmet>
        <meta name="description" content="Useful Legal Services" />
        <meta name="keywords" content="Useful Legal Services" />
        <link
          rel="canonical"
          href="https://rosholmdell.se/services&solutions"
        />
        <title>RosholmDell | {strings.common.serviceAndSolution}</title>
      </Helmet>
      <Navbar blueNav={true} />
      <div
        className={
          modalOpen
            ? commonStyles.commonMainDownContainerStyle
            : commonStyles.commonMainContainerStyle
        }
      >
        <div className={styles.servicesPageInsideContainerStyle}>
          {renderTopContent()}
          <div className={styles.servicesPageInsideSubContainerStyle}>
            {renderOurServices()}
            {renderOurSolutions()}
          </div>
        </div>
        <Footer blueFooter={true} />
      </div>
    </div>
  );
};

export default ServicesAndSolutionsPage;
