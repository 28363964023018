import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import {
  HomePage,
  AboutPage,
  CareerPage,
  OurStoryPage,
  JobPositionPage,
  NewsAndInsights,
  ContactUsPage,
  OurTeam,
  ServicesAndSolutionsPage,
  CommercialIpTechnologyPage,
  DisputeResolution,
  ComplianceRegulatory,
  CorporateAndPrivateAndEquity,
  Environmental,
  EmployementPage,
  MergersAndAcquisitionsPage,
  PublicProcurementPage,
  RailwayAndPublicTransportPage,
  RealEstateAndConstructionsPage,
  WhistleBlowingSolutionPage,
  EmploymentLawAsaServicePage,
  DpoAsaServicePage,
  WhistleBlowerPolicyPage,
  LoginPage,
  PrivacyNoticePage,
  CookieNoticePage,
  TermsAndConditionsPage,
  IpPortfolioManagementPage,
  NewsAndInsightsView,
  TeamMemberDetailsPage,
  NotFound,
} from "../pages";
import {
  LayoutPage,
  DashboardPage,
  CreateJob,
  JobPostList,
  UpdateJobDetails,
  CreateTeamMember,
  TeamMemberList,
  CreateNewsAndInsights,
  NewsAndInsightsList,
  CreateTestimonial,
  TestimonialList,
} from "../pages/admin";
import AuthGaurd from "../authentication/AuthGaurd";
import GuestGaurd from "../authentication/GuestGuard";
import StartupsPage from "../pages/ourservices/startupspage";

const AppRoutes = () => {
  return useRoutes([
    {
      path: "/",
      element: <HomePage />,
    },

    {
      path: "/about",
      element: <AboutPage />,
    },
    {
      path: "/careers",
      element: <CareerPage />,
    },
    {
      path: "/contactus",
      element: <ContactUsPage />,
    },
    {
      path: "/ourteam",
      element: <OurTeam />,
    },
    {
      path: "/teammemberdetails/:id",
      element: <TeamMemberDetailsPage />,
    },
    {
      path: "/ourstory",
      element: <OurStoryPage />,
    },
    {
      path: "/jobposition",
      element: <JobPositionPage />,
    },
    {
      path: "/news&insights",
      element: <NewsAndInsights />,
    },
    {
      path: "/news&insightsview",
      element: <NewsAndInsightsView />,
    },
    {
      path: "/services&solutions",
      element: <ServicesAndSolutionsPage />,
    },
    {
      path: "/services&solutions/commercial&iptechnology",
      element: <CommercialIpTechnologyPage />,
    },
    {
      path: "/services&solutions/disputeresolution",
      element: <DisputeResolution />,
    },
    {
      path: "/services&solutions/compliance&regulatory",
      element: <ComplianceRegulatory />,
    },
    {
      path: "/services&solutions/corporate&privateequity",
      element: <CorporateAndPrivateAndEquity />,
    },
    {
      path: "/services&solutions/startups",
      element: <StartupsPage />,
    },
    {
      path: "/services&solutions/environmental",
      element: <Environmental />,
    },
    {
      path: "/services&solutions/employment",
      element: <EmployementPage />,
    },
    {
      path: "/services&solutions/mergers&acquisitions",
      element: <MergersAndAcquisitionsPage />,
    },
    {
      path: "/services&solutions/publicprocurement",
      element: <PublicProcurementPage />,
    },
    {
      path: "/services&solutions/railway&publictransport",
      element: <RailwayAndPublicTransportPage />,
    },
    {
      path: "/services&solutions/realestate&construction",
      element: <RealEstateAndConstructionsPage />,
    },
    {
      path: "/services&solutions/whistleblowingsolution",
      element: <WhistleBlowingSolutionPage />,
    },
    {
      path: "/services&solutions/employmentlawasaservice",
      element: <EmploymentLawAsaServicePage />,
    },
    {
      path: "/services&solutions/dpoasaservice",
      element: <DpoAsaServicePage />,
    },
    {
      path: "/services&solutions/ipportfoliomanagement",
      element: <IpPortfolioManagementPage />,
    },
    {
      path: "/whistleblowerpolicy",
      element: <WhistleBlowerPolicyPage />,
    },
    {
      path: "/privacynotice",
      element: <PrivacyNoticePage />,
    },
    {
      path: "/cookienotice",
      element: <CookieNoticePage />,
    },
    {
      path: "/terms&conditions",
      element: <TermsAndConditionsPage />,
    },
    {
      path: "/login",
      element: (
        <GuestGaurd>
          <LoginPage />
        </GuestGaurd>
      ),
    },
    {
      path: "",
      element: (
        <AuthGaurd>
          <LayoutPage />
        </AuthGaurd>
      ),
      children: [
        {
          path: "/dashboard",
          element: <DashboardPage />,
        },
        {
          path: "/createnews&insights",
          element: <CreateNewsAndInsights />,
        },
        {
          path: "/news&insightslist",
          element: <NewsAndInsightsList />,
        },
        {
          path: "/editnews&insights",
          element: <CreateNewsAndInsights />,
        },
        {
          path: "/createjob",
          element: <CreateJob />,
        },
        {
          path: "/jobslist",
          element: <JobPostList />,
        },
        {
          path: "/editjob",
          element: <UpdateJobDetails />,
        },
        {
          path: "/createteammember",
          element: <CreateTeamMember />,
        },
        {
          path: "/teammemberlist",
          element: <TeamMemberList />,
        },
        {
          path: "/editteammember",
          element: <CreateTeamMember />,
        },
        {
          path: "/createtestimonial",
          element: <CreateTestimonial />,
        },
        {
          path: "/testimoniallist",
          element: <TestimonialList />,
        },
        {
          path: "/edittestimonial",
          element: <CreateTestimonial />,
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
    {
      path: "/404",
      element: <NotFound />,
    },
  ]);
};

export default AppRoutes;
